import router from '@/router'
import store from '@/store'
import CryptoJS from "crypto-js";
import moment from 'moment'

const TokenKey = 'Token';
const UserInfoKey = 'User-Info';
let usage={
  // aes 加密
  encryptionAES(plaintText){
    var plaintTextVal = plaintText;
    var key = CryptoJS.enc.Utf8.parse('2wijdhru34np'+ moment().format('MMDD'));
    var options = {
        iv: key,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
    };
    var encryptedData = CryptoJS.AES.encrypt(plaintTextVal, key, options);
    var encryptedBase64Str = encryptedData.toString();
    return encryptedBase64Str;
  },
  // aes 解密
  decryptAES(encryptedBase64Str){ 
    var vals = encryptedBase64Str;
    var key = CryptoJS.enc.Utf8.parse('2wijdhru34np'+ moment().format('MMDD'));
    var options = {
        iv: key,
        mode: CryptoJS.mode.CTR,
        padding: CryptoJS.pad.NoPadding
    };
    var decryptedData = CryptoJS.AES.decrypt(vals, key, options);
    var decryptedStr = CryptoJS.enc.Utf8.stringify(decryptedData);
    return decryptedStr
  },
  // 刷新页面缓存数据赋值vuexS
  cacheAssignmentVuex(){
    if(localStorage.getItem(TokenKey)){
      store.commit('setToken', localStorage.getItem(TokenKey))
    }
    if(localStorage.getItem(UserInfoKey)){
      store.commit('setUserInfo', JSON.parse(localStorage.getItem(UserInfoKey)))
    }
  },
  time(){ // 当前时间
    return new Date().getTime(); //1603009495724,精确到毫秒
  },
  // 获取token
  getToken (url) {
    return usage.encryptionAES(store.state.token + '[time]' + usage.time() + '[url]'+url)
  },

  // 储存token
  setToken (token) {
    localStorage.setItem(TokenKey, token);
    store.commit('setToken',token)
  },

  // 删除token
  removeToken () {
    localStorage.removeItem(TokenKey);
    store.commit('removeToken')
  },

  // 获取用户信息
  getUserInfo () {
    return store.state.userInfo;
  },

  // 设置用户信息
  setUserInfo (userInfo) {
    localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
    store.commit('setUserInfo',userInfo)
  },

  // 删除用户信息
  removeUserInfo () {
    localStorage.removeItem(UserInfoKey);
    store.commit('removeUserInfo')
  },

  // 获取某个缓存
  getCache(name){
    if(localStorage.getItem(name)){
      return JSON.parse(localStorage.getItem(name))
    }else{
      return ''
    }
  },

  // 设置某个缓存
  setCache(name,data){
    localStorage.setItem(name,JSON.stringify(data))
  },

  // 删除某个缓存
  removeCache(name){
    localStorage.removeItem(name);
  },

  // 生成随机字符串
  randomString (len) {
    len = len || 32
    const $chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'
    const maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
  },

  // 用户密码验证
  checkPassword (str) {
    // 1.密码必须由字母、数字、特殊符号组成，区分大小写
    // 2.特殊符号包含（. _ ~ ! @ # $ ^ & *）
    // 3.密码长度为8-20位
    // ^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$
    /* eslint-disable */
    let passwordReg = /^.*(?=.*[\@#\!*$!])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/
    /* eslint-enable */
    let noSpace = /^\S*$/
    if (!noSpace.test(str)) {
      return '不能包含空格'
    } else if (str.length > 20 || str.length < 8) {
      return '长度必须为8-20位'
    } else if (!passwordReg.test(str)) {
      return '!@#*$，小写字母，大写字母，数字，均必须有至少一个！'
    } else {
      return false
    }
  },

  // 退出登录方法
  logOut(){
    usage.removeToken();
    usage.removeUserInfo();
    router.push({name: 'loginPage'})
  },
  // 获取时间
  timeNow() {
    let vWeek, vWeek_s, year, month, day, hours, minutes, seconds;
    vWeek = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    let date = new Date();
    year = date.getFullYear();
    month = date.getMonth() + 1;
    day = date.getDate();
    hours = date.getHours();
    hours = hours > 9 ? hours : "0" + hours;
    minutes = date.getMinutes();
    minutes = minutes > 9 ? minutes : "0" + minutes;
    seconds = date.getSeconds();
    seconds = seconds > 9 ? seconds : "0" + seconds;
    vWeek_s = date.getDay();
    let time = year + "-" + month + "-" + day + " " + vWeek[vWeek_s] + " " + hours + ":" + minutes + ":" + seconds + "\t";
    return time
  }
}
export default usage
