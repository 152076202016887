import axios from "axios";
import store from "@/store";
import usage from '../utils/usage'
import { Message } from 'element-ui'

// 生产地址配置
let baseURL = "";
if (process.env.NODE_ENV === "production") {
  baseURL = "/zhcs-whp";
} else {
  baseURL = "/zhcs-whp";
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 15,
  withCredentials: true,
  // headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  // }
});

// 请求拦截
instance.interceptors.request.use(
  (request) => {
    request.headers.ip = '127.0.0.1'
    request.headers.token = usage.getToken(request.url);
    store.dispatch("showLoading", true);
    return request;
  },
  (err) => {
    store.dispatch("showLoading", false);
    return Promise.reject(err);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    store.dispatch("showLoading", false);
    let { data } = response;
    if (data && data.code == 200) {
      let retData = null;
      retData = data.data;
      return retData;
    }else{
      if(data.code == "220112"){
        Message.error('请重新登录')
        usage.logOut()
      }else{
        Message.error(data.error || '系统错误')
      }
    }
  },
  (err) => {
    store.dispatch("showLoading", false);
    // Message.error('服务错误')
    return Promise.reject(err);
  }
);

// get方法
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// post方法
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { $get, $post };
